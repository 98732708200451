//import {create} from "@lottiefiles/lottie-interactivity";

class ResearchPage {
	constructor() {
		this.researchPage = document.querySelector("#research-page");
		this.players = document.querySelectorAll("lottie-player");

		if (this.researchPage) {
			this.run();
		}
	}

	run() {
		const options = {
			threshold: 1
		}
		const watcher = new IntersectionObserver(this.inView, options)
		const firstPlayer = this.players[0];
		firstPlayer.play();

		this.players.forEach(player => {
			player.seek(1);
			watcher.observe(player);

/*
			console.log(lplayer.id);
			console.log(`#${lplayer.id}`)
			const playerID = `#${lplayer.id}`;*/
			/*create({
				mode: 'scroll',
				player: playerID,
				action: {
					visibility: [1, 1],
					type: 'seek'
				}
			})*/
		})
	}

	inView(entries) {
		entries.forEach(entry => {

			if(entry.isIntersecting) {
				const delay = entry.target.getAttribute("data-delay");
				console.log(delay);

				if(delay) {
					setTimeout(() => {
						entry.target.play();
					}, delay);
				} else {
					entry.target.play();
				}
			} else {
			}
		})
	}
}


export default ResearchPage;
