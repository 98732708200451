/**
 * Very basic element watcher can watch against elements or the top of the document!
 *
 * //Todo this is all runtime on scroll certain parts of this should be "bakeable" aka evaluated once
 **/

class SuperElementWatcher {

	constructor() {
		// Gather any element watchers!
		this.elementWatchers = document.querySelectorAll('[data-s-element-watcher]');

		if(this.elementWatchers) {
			this.run();
		}
	}

	run() {
		// dispatch all of the listening events
		this.elementWatchers.forEach((element) => {
			this.scrollHandler('', element);
			window.addEventListener("scroll", event => {this.scrollHandler(event, element)}, {passive: true});
		});
	}


	scrollHandler(event, element) {
		// Setup origin values

		const originValue = element.getAttribute('data-s-element-watcher');
		let originTop = 0;
		let originHeight = 0;

		// Check if an element was passed or a static value from the top
		if(isNaN(originValue)) {
			const originElement = document.querySelector(originValue);

			if(originElement) {
				const originRect = originElement;
				originTop = Math.abs(originRect.top);
				originHeight = originRect.height;
			} else if(originValue === "top") {
				// if top we just want to know when the user scrolls from top
				originTop = 0;
				originHeight = 0;
			} else {
				throw new Error("ElementWatcher: This element does not exist");
			}
		}

		// figures out the height/position of the watching el
		const watcherRect = element.getBoundingClientRect();
		const watcherHeight = watcherRect.height;
		const watcherTop = watcherRect.top;

		// If this value goes negative it activates the watcher element (The element has intersected with the origin)
		const outOfBounds = originHeight - originTop - watcherHeight - watcherTop;

		if(outOfBounds < 0 && window.scrollY !== 0) {
			element.setAttribute('data-s-active', '');
		} else {
			element.removeAttribute('data-s-active');
		}
	}
}

export default SuperElementWatcher;