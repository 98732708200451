import $ from 'jquery';
import whatInput from 'what-input';
window.$ = $;

import AOS from "aos";
import TestimonialSlider from "./lib/testimonial-slider";
import SuperElementWatcher from "./lib/super-element-watcher";
import BetterMenu from "./lib/better-menu";
import NewsSlider from "./lib/news-slider";
import ResearchPage from "./lib/research-page";
import 'aos/dist/aos.css';
import 'swiper/swiper-bundle.css';
// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';


document.addEventListener("DOMContentLoaded",  ()=> {
	const testimonialSlider = new TestimonialSlider();
	const superElementWatcher = new SuperElementWatcher();
	const betterMenu = new BetterMenu();
	const newsSlider = new NewsSlider();
	const researchPage = new ResearchPage();
	setTimeout(() => {
		AOS.init({
			// Global settings:
			disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
			startEvent: "DOMContentLoaded", // name of the event dispatched on the document, that AOS should initialize on
			initClassName: "aos-init", // class applied after initialization
			animatedClassName: "aos-animate", // class applied on animation
			useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
			disableMutationObserver: false, // disables automatic mutations' detections (advanced)
			debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
			throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)

			// Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
			offset: 200, // offset (in px) from the original trigger point
			delay: 0, // values from 0 to 3000, with step 50ms
			duration: 800, // values from 0 to 3000, with step 50ms
			easing: "ease", // default easing for AOS animations
			once: true, // whether animation should happen only once - while scrolling down
			mirror: false, // whether elements should animate out while scrolling past them
			anchorPlacement: "top-bottom" // defines which position of the element regarding to window should trigger the animation
		});
	}, 200);
});
