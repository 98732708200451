import Swiper, { Pagination } from "swiper";

Swiper.use([Pagination]);

class ToolkitSliders {

	constructor() {
		this.sliderEls = document.querySelectorAll("[data-testimonial-slider]");
		this.sliders = [];

		if(this.sliderEls.length) {
			this.run()
		}
	}

	run() {

		this.sliderEls.forEach(slider => {

			const swiper = new Swiper(slider, {
				loop: true,
				slidesPerView: 1,
				spaceBetween: 10,

				pagination: {
					el: ".swiper-pagination"
				}
			});

			/*const prevArrow = slider.querySelector(".swiper-button-prev");
			const nextArrow = slider.querySelector(".swiper-button-next");

			prevArrow.addEventListener("click", ()=> {
				swiper.slidePrev();
			});

			nextArrow.addEventListener("click", ()=> {
				swiper.slideNext();
			});*/


			this.sliders.push(swiper);
		})
	}
}

export default ToolkitSliders;
