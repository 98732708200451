import Swiper, { Pagination } from "swiper";

Swiper.use([Pagination]);

class NewsSlider {

	constructor() {
		this.sliderEls = document.querySelectorAll("[data-news-slider]");
		this.sliders = [];

		if(this.sliderEls.length) {
			this.run()
		}
	}

	run() {

		this.sliderEls.forEach(slider => {

			const swiper = new Swiper(slider, {
				loop: false,
				slidesPerView: 1,
				spaceBetween: 10,

				pagination: {
					el: ".swiper-pagination"
				},
				breakpoints: {
					400: {
						slidesPerView: 1,
						spaceBetween: 10,
					},
					640: {
						slidesPerView: 2,
						spaceBetween: 20,
					},
					1200: {
						slidesPerView: 3,
					}
				}
			});

			this.sliders.push(swiper);
		})
	}
}

export default NewsSlider;
